(function(wnd){
    function FormHandler(container, params) {
        try {
            if (!container) {
                throw 'Container is required!';
            }

            this.container = container;

            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (error) {
            console.error(error);
        }
    }

    FormHandler.prototype = {
        container: undefined,
        form: undefined,
        sendedForm: false,
        type: '',
        init: function() {
            this.setElements();
            this.addDOMEvents();
        },
        setElements: function() {
            this.form = this.container.is('form')
                ? this.container 
                : this.container.find('form');
            this.button = this.container.find('[data-purpose="send-button"]');
        },
        addDOMEvents: function() {
            var _self = this;

            this.button.click(function(e){
                e.preventDefault();
                _self.sendForm();
            });

            this.form.submit(function(e){
                e.preventDefault();
            });
        },
        setParams: function(params) {
            if (params.type) this.setType(params.type);
            if (typeof params.collectFormData === 'function') this.collectFormData = params.collectFormData;
            if (typeof params.handleResponseErrors === 'function') this.handleResponseErrors = params.handleResponseErrors;
            if (typeof params.confirmSubscribe === 'function') this.confirmSubscribe = params.confirmSubscribe;
        },
        setType: function(type) {
            this.type = type;
        },
        sendForm: function() {
            if (!this.sendedForm) {
                var _self = this;
                this.sendedForm = true;

                $.ajax({
                    url: this.form.attr('action'),
                    dataType: 'json',
                    type:'post',
                    data: _self.collectFormData(),
                    beforeSend: function() {
                        _self.form.find('.error-content').remove();
                        _self.form.find('.has-error').removeClass('has-error');
                    },
                    success: function(response) {
                        if (response.success) {
                            $(document).trigger('civishaz.sended_form', _self.form.serializeArray());
                            _self.confirmSubscribe();
                        } else {
                            _self.handleResponseErrors(response.errors);
                        }
                    },
                    complete: function() {
                        setTimeout(function(){
                            _self.sendedForm = false;
                        }, 500);
                    }
                });
            }
        },
        collectFormData: function() {
            return this.form.serialize();
        },
        handleResponseErrors: function(errors) {
            var _self = this;
            $.each(errors, function(key, msg) {
                var field = _self.form.find('[name="'+key+'"]');
                var label = $('label[for="' + field.attr('id') +'"]');
                field.addClass('has-error');
                label.addClass('has-error');

                if (msg.trim() != '') {
                    var errorContent = $('<div>', {
                        class: 'error-content',
                        html: msg,
                    });
                    
                    errorContent.insertAfter(field.is(':checkbox') ? label : field);
                }
            });
        },
        confirmSubscribe: function() {
            this.form.find('.field').val('');
            this.form.find('[type="checkbox"]').prop('checked', false);
            this.container.addClass('success-sent');
        }
    };

    wnd.FormHandler = FormHandler;
})(window);
