(function(wnd) {
    function PropertyMap(container, params) {
        try {
            if (!container) {
                throw Error('Container is required!');
            }

            this.container = container;
            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (error) {
            console.error(error);
        }
    }

    PropertyMap.prototype = {
        container: undefined,
        propertyLocation: null,
        initialized: false,
        map: undefined,
        streetViewMap: undefined,
        init: function () {
            if (this.propertyLocation === null) {
                return;
            }

            this.initMaps();
            this.addDOMEvents();
        },
        addDOMEvents: function() {
            var _self = this;
            
            if (!this.initialized) {
                $(document).on('civishaz.google_maps_loaded', function () {
                    _self.initMaps();
                });
            }
        },
        initMaps: function() {
            if (typeof google === 'undefined') {
                return;
            }
            
            if (typeof this.propertyLocation.coordinates !== 'undefined') {
                this.initDefaultMap();
            }

            if (typeof this.propertyLocation.streetView !== 'undefined') {
                this.initStreetViewMap();
            }
        },
        initDefaultMap: function() {
            this.map = new google.maps.Map(document.getElementById('defaultMap'), {
                zoom: 15,
                mapTypeControl: false,
                scrollwheel: false,
                dragable: false,
                disableDoublClickZoom: false,
                panControl: false,
                streetViewContent: false,
                zoomable: true,
                center: {
                    lat: parseFloat(this.propertyLocation.coordinates.lat),
                    lng: parseFloat(this.propertyLocation.coordinates.lng),
                },
            });

            new google.maps.Marker({
                map: this.map,
                position: {
                    lat: parseFloat(this.propertyLocation.coordinates.lat),
                    lng: parseFloat(this.propertyLocation.coordinates.lng),
                },
                icon: base_url + 'assets/img/svg/marker-big.svg',
            });
        },
        initStreetViewMap: function() {
            var heading = 0;
            var pitch = 0;
            if (typeof this.propertyLocation.streetView.head !== 'undefined') {
                heading = parseInt(this.propertyLocation.streetView.head);
            }

            if (typeof this.propertyLocation.streetView.pitch !== 'undefined') {
                pitch = parseInt(this.propertyLocation.streetView.pitch);
            }

            if (isNaN(heading)) {
                heading = 0;
            }

            if (isNaN(pitch)) {
                pitch = 0;
            }

            this.streetViewMap = new google.maps.StreetViewPanorama(document.getElementById('streetViewMap'), {
                position: {
                    lat: parseFloat(this.propertyLocation.streetView.lat),
                    lng: parseFloat(this.propertyLocation.streetView.lng),
                },
                zoom: 0,
                pov: {
                    heading: heading,
                    pitch: pitch,
                }
            })
        },
        setParams: function (params) {
            if (params.propertyLocation) this.setPropetyLocation(params.propertyLocation);
        },
        setPropetyLocation: function (propertyLocation) {
            this.propertyLocation = propertyLocation;
        }
    };

    wnd.PropertyMap = PropertyMap;
})(window);