(function (wnd) {
    function PropertyFilter(form, params) {
        try {
            if (!form || form.length === 0) {
                throw new Error('Form is required');
            }

            if (!form.is('form')) {
                throw new Error('Form must be a form html element!');
            }

            this.form = form;
            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (error) {
            console.error(error);
        }
    }

    PropertyFilter.prototype = {
        form: undefined,
        activeTab: null,
        button: undefined,
        tabItems: undefined,
        tagsContainer: undefined,
        map: undefined,
        mapInitialized: false,
        markers: [],
        markerBounds: undefined,
        layout: 'list',
        propertyMapDatas: [],
        selectOptions: {},
        options: {
            minimal: false,
            defaultOrder: 'address-asc',
            defaultCenter: {
                lat: 47.523167,
                lng: 21.628325,
            },
        },
        urls: {
            rent: null,
            sale: null,
        },
        init: function () {
            this.setElements();
            this.addDOMEvents();

            if ('map' === this.layout) {
                this.initializeMap();
            }
        },
        addDOMEvents: function () {
            var _self = this;

            this.button.on('click', function (evt) {
                evt.preventDefault();

                if (_self.isMinimal()) {
                    document.location.href = _self.getUrl(true);
                } else {
                    _self.sendFilterForm();
                }
            });

            this.form.find('.filter-tab').on('click', function (e) {
                if ($(this).hasClass('active')) {
                    e.preventDefault();
                }

                $('.filter-tab').removeClass('active');
		        $(this).addClass('active');

                $(document).trigger('civishaz.filter_tab_changed', {
                    type: $(this).data('type')
                });
            });

            if (!this.isMinimal() || 'fonix' === this.activeTab) {
                $(document).on('civishaz.filter_tab_changed', function (evt, data) {
                    document.location.href = _self.getUrl(false);
                });

                this.form.find('.details-button').on('click', function () {
                    _self.form.find('.filter-bottom-content').slideToggle();
                    $(this).toggleClass('opened');
                });

                this.form.find('input, select').on('change', function () {
                    _self.sendFilterForm();
                });

                $('[data-purpose="order-select"]').on('change', function () {
                    _self.sendFilterForm();
                });

                $('[data-purpose="layout-selector"]').on('click', function(evt) {
                    if ($(this).hasClass('active')) {
                        return;
                    }

                    $('[data-purpose="layout-selector"]').removeClass('active');
                    $('[data-purpose="layout-selector"][data-layout="'+ $(this).data('layout') +'"]').addClass('active');
                    _self.changeLayout($(this).data('layout'));
                });

                $(window).on('popstate', function() {
                    document.location.reload();
                });

                $(document).on('click', '[data-purpose="delete-filter-tag"]', function () {
                    _self.resetField($(this).data('key'));
                    $(this).parent().remove();
                    _self.sendFilterForm();
                });

                if ('map' === this.layout && !this.mapInitialized) {
                    $(document).on('civishaz.google_maps_loaded', function () {
                        _self.initializeMap();
                    });
                }

                var featuresSection = $('[data-purpose="features-section"]');
                var mobileFeaturesSection = $('[data-purpose="mobile-features-section"]');
                var featureScrollFn = function () {
                    var scrollTop = $(window).scrollTop();

                    var border = featuresSection.offset().top + featuresSection.outerHeight(true);
                    if (border <= scrollTop && !mobileFeaturesSection.hasClass('active')) {
                        mobileFeaturesSection.addClass('active');
                        $('[data-purpose="map-container"]').addClass('feature-bar-active');
                        setTimeout(function() {
                            $('[data-purpose="map-container"]').addClass('moved');
                        }, 400);
                    } else if (border > scrollTop && mobileFeaturesSection.hasClass('active')) {
                        mobileFeaturesSection.removeClass('active');
                        $('[data-purpose="map-container"]').removeClass('feature-bar-active');
                        setTimeout(function() {
                            $('[data-purpose="map-container"]').removeClass('moved');
                        }, 100);
                    }
                }

                $(window).on('touchend, touchmove, scroll', featureScrollFn);
            }

            if (this.isMinimal()) {
                $(document).on('civishaz.filter_tab_changed', function (evt, data) {
                    _self.updateSelectOptions(data.type);
                });
            }
        },
        setElements: function () {
            this.tabItems = this.form.find('.filter-tab');
            this.button = this.form.find('#filterButton');
            this.tagsContainer = $('[data-purpose="filter-tags-container"]');
        },
        resetField: function (field) {
            if (!field) {
                return;
            }

            var input = this.form.find('[name="' + field + '"]');
            if (input.is(':checkbox')) {
                input.prop('checked', false);
                return;
            }

            if (input.is('select')) {
                input.val(null);
                return;
            }
            
            this.form.find('.inputs [data-key="' + field + '"]').each(function () {
                $(this).val('');
            });
        },
        sendFilterForm: function () {
            var _self = this;
            var filterUrl = this.getUrl(true);
            console.log(filterUrl);

            $.ajax({
                url: filterUrl,
                dataType: 'json',
                type: 'GET',
                beforeSend: function () {
                    window.history.pushState({
                        path: filterUrl,
                    }, '', filterUrl)
                },
                success: function (response) {
                    _self.updatePage(response);       
                }
            })
        },
        updatePage: function (response) {
            this.setPropertyMapDatas(response.propertyMapDatas);

            if (response.selectedCounter > 0) {
                this.form.find('.details-button .counter').html(response.selectedCounter).removeClass('hidden');
            } else {
                this.form.find('.details-button .counter').addClass('hidden');
            }

            $('[data-purpose="counter"]').find('span').html(response.counter);
            $('[data-purpose="properties-list"]').html(response.html);
            
            if (typeof window.lazyLoadInstance !== 'undefined') {
                window.lazyLoadInstance.update();
            }

            this.drawSelectedTags(response.selectedTags);
            this.drawMarkers();
        },
        drawSelectedTags: function (tags) {
            this.tagsContainer.html('');
            if (tags.length === 0) {
                this.tagsContainer.addClass('hidden');
                return;
            }

            for (var i = 0; i < tags.length; i++) {
                var tag = tags[i];

                this.tagsContainer.append($('<div>', {
                    class: 'filter-tag display-inline-flex align-items-center',
                    html: '<div class="label">' + tag.label + '</div><div class="icon-outer cursor-pointer" data-purpose="delete-filter-tag" data-key="' + tag.key + '">' + svg('delete-tag-icon') + '</div>',
                }));
            }
            this.tagsContainer.removeClass('hidden');
        },
        setParams: function (params) {
            if (params.urls) this.setUrls(params.urls);
            if (params.options) this.setOptions(params.options);
            if (params.propertyMapDatas) this.setPropertyMapDatas(params.propertyMapDatas);
            if (params.layout) this.setLayout(params.layout);
            if (params.selectOptions) this.setSelectOptions(params.selectOptions);
            if (params.activeTab) this.setActiveTab(params.activeTab);
        },
        setOptions: function (options) {
            this.options = $.extend(true, {}, this.options, options);
        },
        setUrls: function (urls) {
            this.urls = $.extend({}, this.urls, urls);
        },
        setPropertyMapDatas: function (propertyMapDatas) {
            if (!Array.isArray(propertyMapDatas)) {
                throw Error('Map datas must be an array!');
            }

            this.propertyMapDatas = propertyMapDatas;
        },
        isMinimal: function () {
            return this.options.minimal;
        },
        getUrl: function (parseQuery) {
            var url = this.urls[this.getActiveType()];

            if (parseQuery) {
                var params = this.collectQueryParts();

                if (params) {
                    url += '?' + params;
                }
            }

            return url;
        },
        collectQueryParts: function () {
            var fields = [];

            this.form.find('input, select').each(function() {
                var field = $(this).attr('name');
                var value = null;
                
                if ($(this).is(':checkbox')) {
                    if ($(this).prop('checked')) {
                        value = $(this).val();
                    }
                } else {
                    value = $(this).val();
                }

                if (value) {
                    fields.push(encodeURIComponent(field) + "=" + encodeURIComponent(value));
                }
            });

            var order = $('[data-purpose="order-select"]');
            if (order && order.length === 1) {
                var orderValue = order.val();
                if (orderValue && orderValue !== this.getOption('defaultOrder')) {
                    fields.push(order.attr('name') + '=' + orderValue);
                }
            }

            return fields.length ? fields.join('&') : '';
        },
        getActiveType: function () {
            if (null !== this.activeTab) {
                return this.activeTab;
            }

            var type = '';
            $.each(this.tabItems, function() {
                if ($(this).hasClass('active')) {
                    type = $(this).data('type');
                }
            });

            return type;
        },
        getOption: function (key) {
            return typeof this.options[key] !== 'undefined' ? this.options[key] : null;
        },
        setLayout: function (layout) {
            this.layout = layout;
        },
        setActiveTab: function (activeTab) {
            this.activeTab = activeTab;
        },
        setSelectOptions: function (selectOptions) {
            this.selectOptions = selectOptions;
        },
        changeLayout: function (layout) {
            this.setLayout(layout);
            this.saveLayout();

            $('.properties-list-container').toggleClass('map-layout');
            if ('map' === this.layout) {
                if (!this.mapInitialized) {
                    this.initializeMap();
                } else {
                    this.drawMarkers();
                }
            }
        },
        initializeMap: function () {
            if (typeof google === 'undefined' || this.mapInitialized) {
                return;
            }

            this.map = new google.maps.Map(document.getElementById('propertiesMap'), {
                zoom: 15,
                mapTypeControl: false,
                scrollwheel: false,
                dragable: false,
                disableDoublClickZoom: false,
                panControl: false,
                streetViewContent: false,
                zoomable: true,
                center: this.getOption('defaultCenter'),
            });

            this.drawMarkers();

            this.mapInitialized = true;
        },
        drawMarkers: function () {
            if ('map' !== this.layout) {
                return;
            }

            var _self = this;

            if (this.markers) {
                this.removeMarkers();
            }

            if (0 === this.propertyMapDatas.length) {
                this.map.setCenter(this.getOption('defaultCenter'));
                return;
            }
            this.createMarkerBounds();
            for (var i = 0; i < this.propertyMapDatas.length; i++) {
                var property = this.propertyMapDatas[i];
                var position = {
                    lat: parseFloat(property.coordinates.lat),
                    lng: parseFloat(property.coordinates.lng),
                };

                var marker = new google.maps.Marker({
                    map: this.map,
                    position: position,
                    icon: base_url + 'assets/img/svg/marker-small.svg',
                    propertyId: property.propertyId,
                    link: property.link,
                    title: property.title,
                });

                this.markers.push(marker);
                this.markerBounds.extend(position);

                google.maps.event.addListener(this.markers[i], 'click', function () {
                    var propertyId = this.propertyId;
                    var property = _self.propertyMapDatas.find(function (item) {
                        return item.propertyId === propertyId;
                    });
                    
                    document.location.href = property.link;
                });
            }

            this.map.fitBounds(this.markerBounds);
        },
        createMarkerBounds: function () {
            this.markerBounds = new google.maps.LatLngBounds();
        },
        removeMarkers: function () {
            for (var i = 0; i < this.markers.length; i++) {
                this.markers[i].setMap(null);
                google.maps.event.clearInstanceListeners(this.markers[i], 'click');
                this.markers[i] = null;
            }

            this.markers = [];
        },
        saveLayout: function () {
            $.ajax({
                url: ajax_controller + 'savePropertiesListLayout',
                dataType: 'json',
                type: 'post',
                data: {
                    layout: this.layout,
                },
            });
        },
        updateSelectOptions: function (type) {
            var names = Object.keys(this.selectOptions);
            for (var i = 0; i < names.length; i++) {
                var name = names[i];
                var select = this.form.find('select[name="' + name + '"]');
                if (select) {
                    var options = this.selectOptions[name][type];
                    var value = select.val();

                    select.html('');
                    if (options.length > 0) {
                        for (var j = 0; j < options.length; j++) {
                            select.append('<option value="' + options[j].value + '" ' + (parseInt(options[j].value) === parseInt(value) ? ' selected' : '') + '>' + options[j].label + '</option>')
                        }
                    }
                }
            }
        }
    };

    wnd.PropertyFilter = PropertyFilter;
})(window);