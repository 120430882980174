window.loaded = false;
var windowWidth = 0;
var destroyablePropertySwipers = []

$(window).on('load', function(){
	window.loaded = true;
});

var homeSlider = undefined;


$(document).ready(function() {
	/* MOBILE */
	//create js hook links
	windowWidth = window.innerWidth;
	var resizeFn = function() {
		if (windowWidth != window.innerWidth) {
			windowWidth = window.innerWidth;

			$(document).trigger('civishaz.window_width_changed', windowWidth);

			checkPropertySwipers(windowWidth);
		}
	}

	window.addEventListener('resize', resizeFn);

	$(document).on('click', '.mailtolink', function(e) {
		handleMailtoLink($(this));
	});

	$('[data-purpose="navigation-link"]').each(function(){
		setNavigationUrl($(this));
	});
	
	$('[data-purpose="remodal-open"]').click(function(){
		var target = $(this).data('popup-target');
		if ($(this).data('popup-target')) {
			var item = $('[data-remodal-id="' + $(this).data('popup-target') + '"]');
			if (item.length > 0) {
				item.remodal().open();
			}
		}
	});
	
	if( $('.cookie-bar').length > 0 ) {

		$('.cookie-bar .button').click(function(){
			$('.cookie-bar').addClass('closed');

			$.ajax({
				url:ajax_controller+'setCookieConfirm',
				dataType:'json',
				type:'post',
				data:{cookie:1},
				success: function(){}
			});
		});
	}

	$('[data-purpose="remodal-close"]').click(function(){
		var value = $(this).data('value');
		if (value && value == 'confirm') {
			$('#terms-chk').attr('checked', true);
		}

		$(this).parents('.remodal').remodal().close();
	});

	$('[data-purpose="scroll-to-element"]').click(function(){
		var target = $(this).data('target');
		var item = $(target);
		var timeout = $(this).data('timeout');
		var offset = parseInt($(this).data('offset'));
		if (isNaN(timeout)) {
			timeout = 400;
		}

		if (isNaN(offset)) {
			offset = 0;
		}
		
		if (item.length > 0) {
			$('html, body').animate({scrollTop: item.offset().top - offset}, timeout);
		}
	});

	$('[data-purpose="remodal-open"]').click(function(){
		var target = $(this).data('target');
		if (target) {
			openRemodalContent(target);
		}
	});

	$('[data-purpose="privacy-confirm-button"]').click(function(){
		var type = $(this).data('type');
		$('#privacyChk').prop('checked', type == 'accept');
		$('[data-remodal-id="privacyRemodal"]').remodal().close();
	});


	$('select').change(function(){
		var value = parseInt($(this).val());
		
		if (!value && $(this).hasClass('has-value')) {
			$(this).removeClass('has-value');
		} else if (value && !$(this).hasClass('has-value')) {
			$(this).addClass('has-value');
		}
	}).change();
		
	$('.accordion-list').each(function(ind, el) {

		var options = {};
		var th = $(el);

		if (th.data('single-active-item') != undefined) options.singleActiveItem = true;
		if (th.data('active-index') != undefined) options.activeIndex = th.data('active-index');
		if (th.data('from-width') != undefined) options.fromWidth = parseInt(th.data('from-width'));
		
		new Accordion(th, options);
  	});

  	if (typeof articleGalleryItems !== 'undefined') {
		$('.article-gallery-header-item').click(function(){
			openArticleGallery($(this));
		});
	}

	$('[data-purpose="lang-chooser"]').on('change', function(){
		var option = $(this).find('option:selected');
		if (option && option.length === 1) {
			var url = option.data('url');
			if (url) {
				document.location.href = url;
			}
		}
	});

	initGalleries();
	initTooltipster();
	initHighlightedPropertySwipers();

	$('[data-purpose="open-list-button"]').on('click', function () {
		$(this).parents('.properties-container').find('.properties-list').toggleClass('opened');
		$(this).toggleClass('opened');
	});

	$('[data-purpose="social-share"]').on('click', function() {
		handleSocialShare($(this).data('platform'));
	});

	$('.static-content table').each(function() {
		var tableOuter = $('<div>', {
			class: 'table-container',
		});

		tableOuter.insertAfter($(this));
		tableOuter.append($(this));
	});

	$(document).on('closing', '.remodal', function (e) {
		var target = $(e.target);
		handleVideo(target, 'stop');

		if (target.hasClass('save-closing')) {
			saveModalClosed(target.data('content-id'));
		}
	});
});

function saveModalClosed(itemId) {
	$.ajax({
		url: ajax_controller + 'save_modal_close',
		type: 'post',
		dataType: 'json',
		data: {
			itemId: itemId
		},
		success: function (response) {}
	});
}

var documentTypeSections = undefined;
function initDocumentPageScroll() {
	documentTypeSections = $('[data-purpose="document-type-section"]');
	if (documentTypeSections.length === 0) {
		return;
	}

	checkDocumentPageScroll();

	window.addEventListener('scroll', checkDocumentPageScroll);
	window.addEventListener('touchmove', checkDocumentPageScroll);
	window.addEventListener('touchend', checkDocumentPageScroll);
}

var activeDocumentSection = null;
function checkDocumentPageScroll() {
	var currentDocumentSection = null;
	var scrollTop = $(window).scrollTop();
	documentTypeSections.each(function() {
		var top = $(this).offset().top - 10;
		if (null === currentDocumentSection || top < scrollTop) {
			currentDocumentSection = $(this).attr('id');
		}
	});

	if (currentDocumentSection !== activeDocumentSection) {
		activeDocumentSection = currentDocumentSection;
		$('.type-row').removeClass('active');
		$('.type-row[data-type="' + activeDocumentSection + '"]').addClass('active');
	}
}

function handleSocialShare(platform)
{
	switch (platform) {
		case 'facebook':
			window.open('https://www.facebook.com/sharer/sharer.php?u=' + window.location.href);
			break;
	}
}

function openRemodalContent(remodal_id) {
	var tmp = $('[data-remodal-id="'+remodal_id+'"]');
	if(tmp.length > 0){
	  tmp.remodal().open();

	  handleVideo(tmp, 'start');
	}
}

function debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

function sendAnalyticsEvent(action, category, tag) {
	if (typeof gtag !== 'undefined' && action && category) {
		var evtData = {
			event_category: category,
		};

		if (tag && tag.trim() != '') {
			evtData.event_label = tag;
		}
		
		gtag('event', action, evtData);
	} else if(typeof ga != 'undefined' && action && category){
		if(tag && tag.trim() != ''){
			ga('send', 'event', action, category, tag);
		}else{
			ga('send', 'event', action, category);
		}
	}
}

function svg(id) {
	return '<span class="svg"><svg class="icon ' + id + '"><use xlink:href="#' + id + '" /></svg></span>';
}


function handleMailtoLink(th) {
	if (th.attr('href') == 'javascript:void(0)') {
		var hidden = th.children('.emailhidden');
		th.attr('href', 'mailto:' + hidden.data('user') + '@' + hidden.data('domain'));
	}
}


function loadJs(url, implementationCode){
    var scriptTag = document.createElement('script');
    scriptTag.src = url;

    scriptTag.onload = implementationCode;
    scriptTag.onreadystatechange = implementationCode;

    document.body.appendChild(scriptTag);
};

function setNavigationUrl(item) {
	var address = item.data('address');
	
	if (address) {
		var itemProtocol = item.data('protocol');
		var protocol = itemProtocol ? itemProtocol : 'google';
		var ua = navigator.userAgent.toLowerCase();
		var plat = navigator.platform;
		var device = ua.match(/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera/i) ? ua.match(/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera/i)[0] : false;

		if(!itemProtocol && (device == 'iphone' || device == 'ipad' || device == 'ipod')) {
			function iOSversion() {
					
				if (/iP(hone|od|ad)/.test(navigator.platform)) {
				  var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
				  return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
				}
			}

			var ver = iOSversion() || [0];
			if (ver[0] >= 6) {
				protocol = 'apple';
			}
		}
		
		var urlSuffix = '/?daddr='+address;
		var nav_url = '';
		switch(protocol) {
			case 'apple':
				nav_url = 'maps://';
				break;
			default:
				nav_url = 'https://maps.google.com/maps';
				break;
		}
		item.attr('target', '_blank');
		item.attr('href', nav_url+urlSuffix);
	}
}


function openGallery(item) {
	var groupId = item.data('group-id');
	var index = item.data('index');
	
	if (typeof galleryItems === 'object' && typeof galleryItems[groupId] !== 'undefined') {
		if (!index) {
			index = 0;
		}

		initGallery(item, galleryItems[groupId], index);
	} 
}

function openArticleGallery(item) {
	var groupId = $(item).data('gallery-id');
	if (groupId && typeof articleGalleryItems === 'object' && typeof articleGalleryItems[groupId] !== 'undefined') {
		initGallery(item, articleGalleryItems[groupId]);
	}
}

function initGallery(target, items, index) 
{
	if (!index) {
		index = 0;
	}

	$(target).lightGallery({
		dynamic:true,
		dynamicEl:items,
		index:index,
		hash:false,
		download: false
	});
}

function initGalleries()
{
	if (typeof mediaGroupItems === 'object' && Object.keys(mediaGroupItems).length > 0) {
		$('[data-purpose="gallery-box-item"]:not(.initialized)').each(function(){
			$(this).addClass('initialized');
			$(this).click(function(){
				var id = $(this).data('group-id');
				if (id && typeof mediaGroupItems[id] !== 'undefined') {
					var index = $(this).data('index');
					initGallery($(this), mediaGroupItems[id], index);
				}
			});
		});
	}
}

function initTooltipster()
{
	$.fn.tooltipster('setDefaults', {
		position: 'bottom',
		trigger:'click'
	});

	$('.tooltipster-item:not(.initialized)').each(function(){
		$(this).addClass('initialized');
		var tooltipContent = $(this).data('tooltip-content');
		$(this).tooltipster({
			position:'top',
			contentAsHTML:true,
			trigger: isDeviceTouchable() ? 'click' : 'hover',
			contentCloning: tooltipContent ? true : false,
			interactive: true,
			functionBefore: function(orig, tooltip) {
				$(tooltip.origin).addClass('opened');
			},
			functionAfter: function(orig, tooltip) {
				$(tooltip.origin).removeClass('opened');
			}
		});
	});
}

function initHighlightedPropertySwipers()
{
	var items = document.querySelectorAll('[data-purpose="highlighted-properties-swiper"]');

	items.forEach(function (item) {
		if (!item.classList.contains('swiper-container-initialized') && (windowWidth > 600 || !item.classList.contains('disable-on-mobile'))) {
			var swiperOptions = {
				slidesPerView: 3,
				slidesPerGroup: 3,
				direction: 'horizontal',
				spaceBetween: 30,
				autoHeight: false,
				enabled: false,
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
				pagination: {
					el: '.swiper-pagination',
					clickable: true
				},
				breakpoints: {
					601: {
						slidesPerView: 2,
						slidesPerGroup: 2,
						spaceBetween: 20,
						enabled: true
					},
					1023: {
						slidesPerView: 3,
						slidesPerGroup: 3,
						spaceBetween: 30,
						enabled: true
					}
				},
			};
		
			if (item.querySelectorAll('.swiper-pagination').length === 0) {
				delete(swiperOptions.pagination);
			}
		
			if (item.querySelectorAll('.swiper-pagination').length > 0) {
				item.classList.add('has-pagination');
			}
		
			if (item.querySelectorAll('.swiper-button').length === 0) {
				delete(swiperOptions.navigation);
			}
		
			var swiperObj = initSwiperObject(item, swiperOptions);
		
			if (item.classList.contains('disable-on-mobile')) {
				destroyablePropertySwipers.push(swiperObj);
			}
		}
	});
}

function checkPropertySwipers(windowWidth) {
	if (600 >= windowWidth && 0 < destroyablePropertySwipers.length) {
		destroyablePropertySwipers.forEach(function (item) {
			if (item.el.querySelectorAll('.swiper-pagination').length > 0) {
				item.el.querySelector('.swiper-pagination').innerHTML = '';
			}

			item.destroy();
		});

		destroyablePropertySwipers = [];
	} else if (600 < windowWidth && 0 === destroyablePropertySwipers.length) {
		initHighlightedPropertySwipers();
	}
}

function initSwiperObject(selector, options) {
	return new Swiper(selector, options);
}

function isDeviceTouchable() {
	return (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
}

function initCountdown(container)
{
	var deadline = container.data('deadline');
	if (!deadline) {
		container.hide();
		return;
	}

	var deadlineDate = new Date(deadline);
	var dayLength = 24 * 60 * 60 * 1000;
	var hourLength = 60 * 60 * 1000;
	var minLength = 60 * 1000;

	var updateCountdown = function () {
		var currentDate = new Date();
		var timeRemaining = deadlineDate - currentDate;

		if (timeRemaining <= 0) {
			clearInterval(countdownInterval);
			container.hide();
			return;
		}

		var days = Math.floor(timeRemaining / dayLength);
		var hours = Math.floor((timeRemaining % dayLength) / hourLength);
		var mins = Math.floor((timeRemaining % hourLength) / minLength);
		var secs = Math.floor((timeRemaining % minLength) / 1000);

		container.find('.value[data-type="day"]').html(days);
		container.find('.value[data-type="hour"]').html(hours);
		container.find('.value[data-type="min"]').html(mins);
		container.find('.value[data-type="sec"]').html(secs);
	}

	var countdownInterval = setInterval(updateCountdown, 1000);
}

function handleVideo(element, action) {
	element.find('video').each(function(index, video) {
		if ('stop' === action) {
			video.pause();
		} else if ('start' === action) {
			video.play();
		}
	});
}